/* .Date {
	position: absolute;
	width: 1920px;
	height: 1080px;
	background: white;
} */

.Header {
	/* December 21st */

	position: absolute;
	width: 747px;
	height: 73px;
	left: 577px;
	top: 454px;

	font-family: "Wire One";
	font-style: normal;
	font-weight: 400;
	font-size: 82px;
	line-height: 34px;
	/* or 42% */

	text-align: center;

	color: #21622b;
	/* animation: FadeIn 1s; */
}

.SubHeader {
	/* San Francisco, California */

	position: absolute;
	width: 746px;
	height: 73px;
	left: 585px;
	top: 553px;

	font-family: "Wire One";
	font-style: normal;
	font-weight: 400;
	font-size: 82px;
	line-height: 34px;
	/* or 42% */

	text-align: center;
	/* animation: FadeIn 2s; */

	color: #21622b;
}
