/* .test {
	position: absolute;
	background-color: white;
	width: 1920px;
	height: 1080px;
	background-image: linear-gradient(
		-135deg,
		rgb(255, 255, 255, 1) 0%,
		rgba(184, 202, 223, 1) 46%,
		rgba(231, 237, 244, 0.7) 60%,
		rgba(255, 255, 255, 0.58) 67%,
		rgba(125, 185, 232, 0) 100%
	),
	background-image: linear-gradient(
			to bottom,
			rgba(245, 246, 252, 0.52),
			rgba(117, 19, 93, 0.73)
		),
		url("https://wallpaperaccess.com/full/941788.jpg");
} */
.div {
	background: rgb(88, 88, 88);
}
