.App {
	position: relative;
	min-width: 1920px;
	height: 3000px;
	background-color: rgb(48, 48, 48);
	/* background-color: hwb(300 0% 70%); */
	text-align: center;
	margin: 0 auto;
	font-family: "Wire One";
}

.SlideApp {
	position: relative;
	width: 1920px;
	height: 1080px;
	background-color: white;
	margin: 0 auto;
	top: 5%;
	/* transform: translateY(-50%); */
}

@font-face {
	font-family: "adobe_naskhmedium";
	src: url("./fonts/font0101-webfont.woff2") format("woff2");
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: "adobe_arabicregular";
	src: url("./fonts/font0102-webfont.woff2") format("woff2");
	font-weight: normal;
	font-style: normal;
}
